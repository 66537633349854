import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import SEO from "../components/SEO"
import Hero from "../components/hero"
import linkResolver from "../utils/linkResolver"

const usecaseDetailsPage = ({
  data,
  pageContext: { locale, usecaseDetails },
  location,
}) => {
  return (
    <>
      <SEO
        title={RichText.asText(usecaseDetails.use_case_title)}
        desc={RichText.asText(usecaseDetails.use_case_subtitle)}
        metadata={data.site.siteMetadata}
        locale={locale}
        pathname={location.pathname}
      />
      <Hero
        hero={{
          title: usecaseDetails.use_case_title,
          subtitle: usecaseDetails.use_case_subtitle,
          image: {
            image: usecaseDetails.use_case_image,
            sharp: usecaseDetails.use_case_imageSharp,
          },
        }}
      />
      <section className="section is-small">
        <div className="container">
          <div className="columns">
            <div className="column is-7 hasRichText">
              {usecaseDetails.use_case_content &&
                RichText.render(usecaseDetails.use_case_content, linkResolver)}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default usecaseDetailsPage

usecaseDetailsPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export const usecasesQuery = graphql`
  query UsecaseDetailsPageQuery {
    site {
      ...SiteInformation
    }
  }
`
